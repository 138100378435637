.examples {
  isolation: isolate;
}

.back {
  padding: 1rem;
  position: relative;
  z-index: 1;
}

.example {
  position: fixed;
  inset: 0;
  z-index: 0;
}