html {
  height: 100%;
}

body {
  background-color: black;
  min-height: 100%;
  margin: 0;
  padding: 0;
  color: white;
  text-shadow: 1px 1px 1px black;
}

#root,
.app {
  min-height: 100%;
}

a {
  color: #999;
}

a:hover,
a:focus {
  color: #ccc;
}

ul {
  margin: 0;
}